.slick-slide > div {
  display: grid;
  place-items: start end;
  width: 80%;
  margin-top: 5rem;
  margin: auto;
  padding: 0;
  border-radius: 4rem;
}

@media screen and (max-width: 512px) {
  .slick-list .slick-track {
    padding-left: 30%;
  }
}
